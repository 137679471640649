/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import { graphql, StaticQuery } from "gatsby"
import React from "react"
import Helmet from "react-helmet"
import Header from "./header"
import headerStyles from "./header.module.css"
// import "./layout.css"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet>
          <link
            rel="icon"
            type="image/png"
            href="/favicon-32x32.png"
            sizes="32x32"
          />
          <link
            rel="icon"
            type="image/png"
            href="/favicon-48x48.png"
            sizes="48x48"
          />
          <link rel="apple-touch-icon" href="/appicon-touch-32x32.png" />
          <link rel="apple-touch-icon" href="/appicon-touch-64x64.png" />
          <link rel="apple-touch-icon" href="/appicon-touch-96x96.png" />
        </Helmet>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div
          className={headerStyles.headerContentAdjust}
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0px 1.0875rem 1.45rem`,
          }}
        >
          <main>{children}</main>
          <footer style={{ marginTop: "12em", color: "#888" }}>© 2019</footer>
        </div>
      </>
    )}
  />
)

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// }

export default Layout
