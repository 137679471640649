import { Link } from "gatsby"
import React from "react"
import styles from "./header.module.css"

const Header = ({ siteTitle }) => (
  <header className={`${styles.root} ${styles.FixedScrolled}`}>
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <h1 className={styles.logo}>
        <Link to="/">{siteTitle}</Link>
      </h1>
    </div>
  </header>
)

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
